<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link v-if="false" class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 "
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            class="fade-in-image"
            fluid
            :src="imgUrl"
            alt="Login V2"
            width="500"
            height="500"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Adventure starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              ref="registerForm"
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >

              <b-row class="">

                <b-col lg="6">
                  <b-form-group
                    label="First name"
                    label-for="register-Firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      vid="firstname"
                      rules="required"
                    >
                      <b-form-input
                        id="register-firstname"
                        v-model="user.first_name"
                        name="register-firstname"
                        :state="errors.length > 0 ? false:null"
                        placeholder="John"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col lg="6">
                  <b-form-group
                    label="Last Name"
                    label-for="register-lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      vid="lastname"
                      rules="required"
                    >
                      <b-form-input
                        id="register-lastname"
                        v-model="user.last_name"
                        name="register-lastname"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- username -->
              <b-form-group
                label="Username"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required|alpha-dash"
                >
                  <b-form-input
                    id="register-username"
                    v-model="user.username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Mobile Number"
                label-for="register-mobile-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile Number"
                  vid="mobile-number"
                  rules="required|min:8"
                >
                  <b-form-input
                    id="register-mobile-number"
                    v-model="user.mobile_number"
                    name="register-mobile-number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="9812312"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      name="register-password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="register-confirm-password"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-confirm-password"
                      v-model="user.confirmPassword"
                      autocomplete="new-password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-confirm-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || status == false"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <div v-if="false" class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div v-if="false" class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {},

      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/logo/register-logo.png'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/register-logo.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    initializeJWT() {
      const userDetails = {
        user: {
          ...this.user,
        },
      }

      this.$refs.registerForm.validate().then(validationSuccess => {
        if (validationSuccess) {
          useJwt
            .register(userDetails)
            .then(response => {
              const { success, message, output } = response.data
              if (success) {
                useJwt.setToken(output.token)
                // this.$emit('register-process', {
                //   full_name: output.full_name,
                //   email: output.email.toLowerCase(),
                // })
                this.isLoading = false

                this.$toastSuccess('Registration Successful')

                // localStorage.setItem('accessToken', output.token) // Update user details

                // TODO: UPDATE_USER_INFO is not working properly yet
                localStorage.setItem('userInfo', JSON.stringify(output)) // Update user details
                this.$store.commit('auth/UPDATE_USER_INFO', output, {
                  root: true,
                })

                if (this.$route.name === 'listings-page' || this.$route.name === 'page-event') {
                  this.$emit('user-registered')
                } else {
                  const link = '/'
                  this.$router.push(link)
                }
              } else {
                this.isLoading = false
                this.$toast({
                  component: this.$toastContent,
                  props: {
                    title: 'Registration Failed',
                    icon: 'XCircleIcon',
                    text: message,
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$handleResponseError(error)
            })
        } else {
          this.isLoading = false
          this.$toastWarning('Sign Up Error', 'Please correct all the form errors before proceeding')
        }
      })
    },
    register() {
      // this.isLoading = true
      this.initializeJWT()
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
